<template>
  <VAlert
    :value="isOpen"
    color="db-purple"
    class="global-notice"
    transition="fade-slow"
    mode="in-out"
  >
    <VLayout row justify-center>
      <VFlex xs10 sm8 lg6>
        <BaseSiteContent
          tag="h2"
          tag-class="header-1 mb-3"
          content-key="covid_alert_title"
          default-content="An Update from The Daily Beet"
        />
        <BaseSiteContent
          tag="p"
          tag-class="r-headline mb-3"
          content-key="covid_alert_text"
          default-content="The Daily Beet on Magazine is open seven days a week from 8am-8pm for Delivery/Curbside Pickup. The Daily Beet on Girod will remain closed until further notice.

          Thank you for your support during this time!"
        />
        <BaseButton
          color="db-green"
          class="ml-0"
          href="https://direct.chownow.com/order/8485/locations"
          >Order Online</BaseButton
        >
        <BaseButton color="white" class="ml-0" outline @click="buttonClick"
          >Hide Notice</BaseButton
        >
      </VFlex>
    </VLayout>
  </VAlert>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'DailyBeetGlobalNotice',
  data() {
    return {}
  },
  computed: {
    ...mapGetters('nav', ['getGlobalNoticeState']),
    isOpen: {
      get() {
        return this.getGlobalNoticeState
      },
      set() {
        this.toggleGlobalNotice()
      },
    }, // isOpen
  }, // computed
  methods: {
    ...mapActions('nav', ['toggleGlobalNotice']),
    buttonClick() {
      this.toggleGlobalNotice()
    }, // buttonClick
  },
}
</script>

<style lang="scss" scoped>
.global-notice {
  height: auto;
  min-height: 60vh;
}

.fade-slow-enter-active,
.fade-slow-leave-active {
  transition: opacity 1s;
}
.fade-slow-enter,
.fade-slow-leave-active {
  opacity: 0;
}
.fade-slow-enter-active {
  transition-delay: 1s;
}
</style>
